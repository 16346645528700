import React, { createContext, useReducer } from "react"

const initialState = {
  menu: "initState",
  isFirstScreen: true,
}

export const GlobalState = createContext(initialState)

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "RESET_BURGER":
        return { ...state, menu: "initState" }
      case "OPEN_MENU":
        return { ...state, menu: "openMenu" }
      case "EXPAND_SERVICES":
        return { ...state, menu: "expandServices" }
      case "SET_IS_FIRST_SCREEN":
        return { ...state, isFirstScreen: action.isFirstScreen }
      default:
        throw new Error("Bad Action Type")
    }
  }, initialState)

  return (
    <GlobalState.Provider value={{ state, dispatch }}>
      {children}
    </GlobalState.Provider>
  )
}

export default GlobalContextProvider
