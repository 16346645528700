/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

 /**
  * import fonts css manually so it will have loacl cache header due to a
  * styled-component bug.
  * 
  * SC won't give cache header to fonts locally so every re-render will make
  * custom fonts re-download and feels flick.
  */
import "./src/components/Layout/GlobalStyle/fonts.css"
import "./src/components/Layout/GlobalStyle/pre-loader.css"

/**
 * Inject context to entired app.
 */
import React from "react"
import GlobalContextProvider from "./src/utilities/GlobalContextProvider"
export const wrapRootElement = ({ element }) => {
  return <GlobalContextProvider>{element}</GlobalContextProvider>
}

/**
 * Custom Loading Screen
 */
export const onInitialClientRender = () => {
  document.querySelector("#___loader").classList.add("show")
  setTimeout(function () {
    document.querySelector("#___loader .path").classList.add("hide")
    document.querySelector("#__loader_logo").classList.add("animated")
    setTimeout(function (){
      document.getElementById("___loader").style.opacity = 0
      document.getElementById("___loader").style.pointerEvents = "none"
    }, 3000)
  }, 3000)
}
